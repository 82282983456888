const login = () => import("@/views/login/login.vue");
const index = () => import("@/views/login/index.vue");
export default [
	{
		path: '/login', // 注册
		name: 'login',
		component: login,
		meta: {
			title: '注册',
			keepAlive: false
		}
	},
	{
		path: '/:recomParam(recom.+)', // 注册
		name: 'index',
		component: index,
		meta: {
			title: '注册',
			keepAlive: false
		}
	},
]