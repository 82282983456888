// 将整个 bridge 暴露出去
// 便于在代码中查看哪些地方是调用的原生方法
import { USER_AGENT } from '@/assets/js/constant'
import { MessageBox } from "mint-ui";
import { objectToKeyVal } from '@/assets/js/utils'
export const ctxPath = window.location.origin;

// 判断是否在app内打开
const getUserAgentType = () => {
	const userAgentStr = navigator.userAgent
	if (userAgentStr.includes(USER_AGENT.ANDROID.K)) {
		return 1
	} else if (userAgentStr.includes(USER_AGENT.IOS.K)) {
		return 2;
	} else if (userAgentStr.includes('micromessenger')) {
		return 4;
	} else {
		return 3;
	}
}

// 获取用户token
const getH5Token = (res) => {
	return new Promise((resolve, reject) => {
		try {
			// localStorage.setItem('userInfo', '123456789');
			resolve(localStorage.getItem('userInfo'));
		} catch (e) {
			
		}
	});
}

// 获取用户token
const getToken = () => {
	var _type;
	try {
		_type = getUserAgentType();
		if (_type == 1) {
			window.Android.getTokenParameter();
		} else if (_type == 2) {
			window.webkit.messageHandlers.getTokenParameter.postMessage({
				token: ''
			});
		}
	} catch (e) {
		MessageBox('错误信息 getToken', JSON.stringify(e))
	}
	return new Promise((resolve, reject) => {
		try {
			window.klyzParameter = (msg) => {
				var _type = getUserAgentType();
				if (_type == 1) {
					localStorage.setItem('userInfo', JSON.stringify(msg));
					resolve(JSON.stringify(msg));
				} else if (_type == 2) {
					localStorage.setItem('userInfo', msg);
					resolve(msg);
				} else {
					localStorage.setItem('userInfo', JSON.stringify(msg));
					resolve(JSON.stringify(msg));
				}
			};
			if (_type != 1 && _type != 2) {
				window.klyzParameter({
					token: 'jwt_eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJmNDM0OTAxNjU4NmU0NDdjYTExZWJlN2JkNGYxNzc3MCIsImlhdCI6MTYzMTE3NTg1Niwic3ViIjoiMTQzMDA2NDYzMjEyMjQ0NTgyNCIsImV4cCI6MTYzMTc4MDY1Nn0.kM2Pw3iYovPRTsQiX-rGXsT4ctEDKzjPNuevo3EllCg',
					userId: '1411975294658678784',
					iosType: "1"
				})
			}
		} catch (e) {
			
		}
	});
}

// 设置标题
// IOS ZSTRequestPostTitle
export const postTitle = (title) => {
	try {
		let _type = getUserAgentType();
		if (_type == 1) {
			window.document.title = title;
		} else if (_type == 2) {
			window.webkit.messageHandlers.ZSTRequestPostTitle.postMessage(title);
		} else {
			window.document.title = title;
		}
	} catch (e) {
		MessageBox(e);
	}
};

// 关闭webview
const closeWebView = () => {
	try {
		let _type = getUserAgentType();
		if (_type == 1) {
			window.Android.goBackReturnApp();
		} else if (_type == 2) {
			window.webkit.messageHandlers.goBackReturnApp.postMessage(null);
		}
	} catch (e) {
		MessageBox('错误信息 closeWebView', JSON.stringify(e))
	}
}

// 获取地址栏参数
const getQueryString = function() {
	var name, value, i;
	var str = window.location.href;
	var num = str.indexOf("?");
	str = str.substr(num + 1);
	var arrtmp = str.split("&"); //以"&"为节点，返回字符串数组
	for (i = 0; i < arrtmp.length; i++) {
		num = arrtmp[i].indexOf("=");
		if (num > 0) {
			name = arrtmp[i].substring(0, num); //获得字段名
			value = arrtmp[i].substr(num + 1); //获得字段名对应的字段值
			this[name] = value; //this代表当前对象（QueryString的对象）
		}
	}
}

// 导航栏右侧标题
const getrightSideOfTheTitle = (data) => {
	try {
		let _type = getUserAgentType();
		if (_type == 1) {
			window.Android.rightSideOfTheTitle(JSON.stringify(data));
		} else if (_type == 2) {
			window.webkit.messageHandlers.rightSideOfTheTitle.postMessage(JSON.stringify(data));
		}
	} catch (e) {
		MessageBox('错误信息 rightSideOfTheTitle', JSON.stringify(e))
	}
	return new Promise((resolve, reject) => {
		try {
			let _type = getUserAgentType();
			window.rightTitle = (msg) => {
				resolve(msg);
			};
			if (_type != 1 && _type != 2) {
				// resolve(123);
			}
		} catch (e) {
			MessageBox('rightTitle', JSON.stringify(e))
		}
	});
}

// 获取定位
const getPosition = () => {
	try {
		let _type = getUserAgentType();
		if (_type == 1) {
			window.Android.requestGPS();
		} else if (_type == 2) {
			window.webkit.messageHandlers.requestGPS.postMessage(null);
		}
	} catch (e) {
		MessageBox('错误信息 getPosition', JSON.stringify(e))
	}
	return new Promise((resolve, reject) => {
		window.getGPSPosition = (msg) => {
			try {
				let _type = getUserAgentType();
				var LONG, LAT, ADDRESS;
				if (_type == 1) {
					LONG = JSON.parse(JSON.stringify(msg)).gasAddressLongitude; //经度
					LAT = JSON.parse(JSON.stringify(msg)).gasAddressLatitude; //纬度
					ADDRESS = JSON.parse(JSON.stringify(msg)).address;
				} else if (_type == 2) {
					msg = String(msg)
					LONG = JSON.parse(msg).gasAddressLongitude; //经度
					LAT = JSON.parse(msg).gasAddressLatitude; //纬度
					ADDRESS = JSON.parse(msg).address;
				} else {

				}
				var obj = {
					gasAddressLongitude: LONG,
					gasAddressLatitude: LAT,
					address: ADDRESS
				}
				resolve(obj);
			} catch (e) {
				reject(e);
			}
		}
		let _type = getUserAgentType();
		if (_type != 1 && _type != 2) {
			setTimeout(() => {
				// 调试
				// resolve({ gasAddressLongitude: '105.4925060000', gasAddressLatitude: '30.4235750000', address: "杭州市" }); //测试用
				resolve({
					gasAddressLongitude: '120.256723',
					gasAddressLatitude: '30.230658'
				}); //测试用
			}, 200)
		}
	});
};

// 隐藏导航栏
const hiddenNavbar = (code) => {
	callAppMethod(17)
}

// 显示导航栏
const showNavbar = (code) => {
	callAppMethod(20)
}

// 标题
const changeTitle = (title = '') => {
	document.title = title
}

// 修改标题栏颜色
export const changeNavColor = (color) => {
	try {
		let _type = getUserAgentType();
		if (_type == 1) {
			window.Android.postAndroidColor('#' + color);
		}
	} catch (e) {
		MessageBox('错误信息 changeNavColor', JSON.stringify(e))
	}
}

// 禁止下拉刷新
const disabledRefresh = () => {
	try {
		let _type = getUserAgentType();
		if (_type == 1) {
			window.Android.getAndroidNoShare();
		} else if (_type == 2) {
			window.webkit.messageHandlers.ZSTRequestNoReady.postMessage({
				data: ''
			});
		}
	} catch (e) {}
}

// 打开网页
// @param url
// 绝对 url
const openWeb = (url, isNotReload) => {
	try {
		let _type = getUserAgentType();
		if (_type == 1) {
			window.Android.openNewWeb(JSON.stringify({
				url: url
			}));
		} else if (_type == 2) {
			window.webkit.messageHandlers.openNewWeb.postMessage(url);
		} else {
			window.location.href = url
		}
	} catch (e) {
		MessageBox('错误信息 openNewWeb', JSON.stringify(e))
	}
};

const isIphoneX = () => {
	let isIphone = /iphone/gi.test(window.navigator.userAgent)
	let windowW = window.screen.width
	let windowH = window.screen.height
	let pixelRatio = window.devicePixelRatio
	let isIPhoneX = isIphone && pixelRatio && pixelRatio === 3 && windowW === 375 && windowH === 812
	let isIPhoneXSMax = isIphone && pixelRatio && pixelRatio === 3 && windowW === 414 && windowH === 896
	let isIPhoneXR = isIphone && pixelRatio && pixelRatio === 2 && windowW === 414 && windowH === 896
	if (isIPhoneX || isIPhoneXSMax || isIPhoneXR) {
		return true
	}
	return false
}

const jsCall = (action, param) => {
	let iframe = document.createElement('iframe')
	let srcStr = 'hb-action://' + action
	const callbackName = 'callback_' + parseInt(Math.random() * 100000)
	param.callbackName = callbackName
	if (param) {
		srcStr += '?' + objectToKeyVal(param)
	}
	iframe.setAttribute('src', srcStr)
	iframe.setAttribute('style', 'visibility:hidden;opacity:0;')
	iframe.setAttribute('height', '0px')
	iframe.setAttribute('width', '0px')
	iframe.setAttribute('frameborder', '0')
	document.body.append(iframe)
	setTimeout(() => {
		document.body.removeChild(iframe)
		iframe = null
	})
	return new Promise(function(resolve, reject) {
		window[callbackName] = (result) => {
			window[callbackName] = null
			delete window[callbackName]
			resolve(result)
		}
	})
}

// 统一方法
const bindEvent = (event) => {
	try {
		let _type = getUserAgentType();
		if (_type == 1) {
			window.Android.bindEvent(JSON.stringify(event));
		} else if (_type == 2) {
			window.webkit.messageHandlers.bindEvent.postMessage(JSON.stringify(event));
		}
	} catch (e) {
		MessageBox('错误信息 bindEvent', JSON.stringify(e))
	}
}

// 培训去看直播
const goTrainLive = (json) => {
	try {
		let _type = getUserAgentType();
		if (_type == 1) {
			let _data = JSON.stringify(json);
			window.Android.goTrainLive(_data);
		} else if (_type == 2) {
			window.webkit.messageHandlers.goTrainLive.postMessage(json);
		}
	} catch (e) {
		MessageBox('错误信息 goTrainLive', JSON.stringify(e))
	}
	return new Promise((resolve, reject) => {
		window.KLYZPaySuccess = (msg) => {
			resolve(msg);
		}
	})
}

// 商品详情
const productDetails = (event) => {
	try {
		let _type = getUserAgentType();
		if (_type == 1) {
			window.Android.productDetails(JSON.stringify(event));
		} else if (_type == 2) {
			window.webkit.messageHandlers.productDetails.postMessage(JSON.stringify(event));
		}
	} catch (e) {
		MessageBox('错误信息 productDetails', JSON.stringify(e))
	}
}

// 课程详情
const courseDetails = (event) => {
	try {
		let _type = getUserAgentType();
		if (_type == 1) {
			window.Android.courseDetails(JSON.stringify(event));
		} else if (_type == 2) {
			window.webkit.messageHandlers.courseDetails.postMessage(JSON.stringify(event));
		}
	} catch (e) {
		MessageBox('错误信息 courseDetails', JSON.stringify(e))
	}
}

// 专栏详情
const columnDetails = (event) => {
	try {
		let _type = getUserAgentType();
		if (_type == 1) {
			window.Android.columnDetails(JSON.stringify(event));
		} else if (_type == 2) {
			window.webkit.messageHandlers.columnDetails.postMessage(JSON.stringify(event));
		}
	} catch (e) {
		MessageBox('错误信息 columnDetails', JSON.stringify(e))
	}
}

const HB = {
	getUserAgentType,
	getQueryString, // 获取地址栏参数
	openWeb, // 打开网页
	changeTitle, // 标题
	showNavbar, // 显示导航栏
	getPosition, // 获取定位
	closeWebView, // 关闭webview
	disabledRefresh, // 禁止下拉刷新
	getToken, // 获取token
	getrightSideOfTheTitle, // 导航栏右侧标题
	bindEvent, // 公共方法
	getH5Token, // H5登录
	goTrainLive,
	columnDetails,
	productDetails,
	courseDetails
}
export default HB
