const about = () => import("@/views/agreement/about.vue");
const PrivacyPolicyZh = () => import("@/views/agreement/PrivacyPolicyZh.vue");
const PrivacyPolicyEn = () => import("@/views/agreement/PrivacyPolicyEn.vue");
const PrivacyPolicyJa = () => import("@/views/agreement/PrivacyPolicyJa.vue");
const PrivacyPolicyKo = () => import("@/views/agreement/PrivacyPolicyKo.vue");
const PrivacyPolicyRu = () => import("@/views/agreement/PrivacyPolicyRu.vue");
const PrivacyPolicyTh = () => import("@/views/agreement/PrivacyPolicyTh.vue");
const PrivacyPolicyVi = () => import("@/views/agreement/PrivacyPolicyVi.vue");

const UserServiceAgreementZh = () => import("@/views/agreement/UserServiceAgreementZh.vue");
const UserServiceAgreementEn = () => import("@/views/agreement/UserServiceAgreementEn.vue");
const UserServiceAgreementJa = () => import("@/views/agreement/UserServiceAgreementJa.vue");
const UserServiceAgreementKo = () => import("@/views/agreement/UserServiceAgreementKo.vue");
const UserServiceAgreementRu = () => import("@/views/agreement/UserServiceAgreementRu.vue");
const UserServiceAgreementTh = () => import("@/views/agreement/UserServiceAgreementTh.vue");
const UserServiceAgreementVi = () => import("@/views/agreement/UserServiceAgreementVi.vue");

const RechargeAgreementZh = () => import("@/views/agreement/RechargeAgreementZh.vue");
const RechargeAgreementEn = () => import("@/views/agreement/RechargeAgreementEn.vue");
const RechargeAgreementJa = () => import("@/views/agreement/RechargeAgreementJa.vue");
const RechargeAgreementKo = () => import("@/views/agreement/RechargeAgreementKo.vue");
const RechargeAgreementRu = () => import("@/views/agreement/RechargeAgreementRu.vue");
const RechargeAgreementTh = () => import("@/views/agreement/RechargeAgreementTh.vue");
const RechargeAgreementVi = () => import("@/views/agreement/RechargeAgreementVi.vue");

const LiveAgreementZh = () => import("@/views/agreement/LiveAgreementZh.vue");
const LiveAgreementEn = () => import("@/views/agreement/LiveAgreementEn.vue");
const LiveAgreementJa = () => import("@/views/agreement/LiveAgreementJa.vue");
const LiveAgreementKo = () => import("@/views/agreement/LiveAgreementKo.vue");
const LiveAgreementRu = () => import("@/views/agreement/LiveAgreementRu.vue");
const LiveAgreementTh = () => import("@/views/agreement/LiveAgreementTh.vue");
const LiveAgreementVi = () => import("@/views/agreement/LiveAgreementVi.vue");

const StoreOpeningAgreementZh = () => import("@/views/agreement/StoreOpeningAgreementZh.vue");
const StoreOpeningAgreementEn = () => import("@/views/agreement/StoreOpeningAgreementEn.vue");
const StoreOpeningAgreementJa = () => import("@/views/agreement/StoreOpeningAgreementJa.vue");
const StoreOpeningAgreementKo = () => import("@/views/agreement/StoreOpeningAgreementKo.vue");
const StoreOpeningAgreementRu = () => import("@/views/agreement/StoreOpeningAgreementRu.vue");
const StoreOpeningAgreementTh = () => import("@/views/agreement/StoreOpeningAgreementTh.vue");
const StoreOpeningAgreementVi = () => import("@/views/agreement/StoreOpeningAgreementVi.vue");

export default [
	{
		path: '/about', // 关于我们
		name: 'about',
		component: about,
		meta: {
			title: '关于我们',
			keepAlive: false
		}
	},
	{
		path: '/PrivacyPolicyZh', // 隐私政策 zh
		name: 'PrivacyPolicyZh',
		component: PrivacyPolicyZh,
		meta: {
			title: '隐私政策',
			keepAlive: false
		}
	},
	{
		path: '/PrivacyPolicyEn', // 隐私政策 en
		name: 'PrivacyPolicyEn',
		component: PrivacyPolicyEn,
		meta: {
			title: '隐私政策',
			keepAlive: false
		}
	},
	{
		path: '/PrivacyPolicyJa', // 隐私政策 ja
		name: 'PrivacyPolicyJa',
		component: PrivacyPolicyJa,
		meta: {
			title: '隐私政策',
			keepAlive: false
		}
	},
	{
		path: '/PrivacyPolicyKo', // 隐私政策 ko
		name: 'PrivacyPolicyKo',
		component: PrivacyPolicyKo,
		meta: {
			title: '隐私政策',
			keepAlive: false
		}
	},
	{
		path: '/PrivacyPolicyRu', // 隐私政策 Ru
		name: 'PrivacyPolicyRu',
		component: PrivacyPolicyRu,
		meta: {
			title: '隐私政策',
			keepAlive: false
		}
	},
	{
		path: '/PrivacyPolicyTh', // 隐私政策 Th
		name: 'PrivacyPolicyTh',
		component: PrivacyPolicyTh,
		meta: {
			title: '隐私政策',
			keepAlive: false
		}
	},
	{
		path: '/PrivacyPolicyVi', // 隐私政策 Vi
		name: 'PrivacyPolicyVi',
		component: PrivacyPolicyVi,
		meta: {
			title: '隐私政策',
			keepAlive: false
		}
	},
	{
		path: '/UserServiceAgreementZh', // 用户服务协议 zh
		name: 'UserServiceAgreementZh',
		component: UserServiceAgreementZh,
		meta: {
			title: '用户服务协议',
			keepAlive: false
		}
	},
	{
		path: '/UserServiceAgreementEn', // 用户服务协议 en
		name: 'UserServiceAgreementEn',
		component: UserServiceAgreementEn,
		meta: {
			title: '用户服务协议',
			keepAlive: false
		}
	},
	{
		path: '/UserServiceAgreementJa', // 用户服务协议 ja
		name: 'UserServiceAgreementJa',
		component: UserServiceAgreementJa,
		meta: {
			title: '用户服务协议',
			keepAlive: false
		}
	},
	{
		path: '/UserServiceAgreementKo', // 用户服务协议 ko
		name: 'UserServiceAgreementKo',
		component: UserServiceAgreementKo,
		meta: {
			title: '用户服务协议',
			keepAlive: false
		}
	},
	{
		path: '/UserServiceAgreementRu', // 用户服务协议 Ru
		name: 'UserServiceAgreementRu',
		component: UserServiceAgreementRu,
		meta: {
			title: '用户服务协议',
			keepAlive: false
		}
	},
	{
		path: '/UserServiceAgreementTh', // 用户服务协议 Th
		name: 'UserServiceAgreementTh',
		component: UserServiceAgreementTh,
		meta: {
			title: '用户服务协议',
			keepAlive: false
		}
	},
	{
		path: '/UserServiceAgreementVi', // 用户服务协议 Vi
		name: 'UserServiceAgreementVi',
		component: UserServiceAgreementVi,
		meta: {
			title: '用户服务协议',
			keepAlive: false
		}
	},
	{
		path: '/RechargeAgreementZh', // 充值服务协议 zh
		name: 'RechargeAgreementZh',
		component: RechargeAgreementZh,
		meta: {
			title: '充值服务协议',
			keepAlive: false
		}
	},
	{
		path: '/RechargeAgreementEn', // 充值服务协议 en
		name: 'RechargeAgreementEn',
		component: RechargeAgreementEn,
		meta: {
			title: '充值服务协议',
			keepAlive: false
		}
	},
	{
		path: '/RechargeAgreementJa', // 充值服务协议 ja
		name: 'RechargeAgreementJa',
		component: RechargeAgreementJa,
		meta: {
			title: '充值服务协议',
			keepAlive: false
		}
	},
	{
		path: '/RechargeAgreementKo', // 充值服务协议 ko
		name: 'RechargeAgreementKo',
		component: RechargeAgreementKo,
		meta: {
			title: '充值服务协议',
			keepAlive: false
		}
	},
	{
		path: '/RechargeAgreementRu', // 充值服务协议 Ru
		name: 'RechargeAgreementRu',
		component: RechargeAgreementRu,
		meta: {
			title: '充值服务协议',
			keepAlive: false
		}
	},
	{
		path: '/RechargeAgreementTh', // 充值服务协议 To
		name: 'RechargeAgreementTh',
		component: RechargeAgreementTh,
		meta: {
			title: '充值服务协议',
			keepAlive: false
		}
	},
	{
		path: '/RechargeAgreementVi', // 充值服务协议 Vi
		name: 'RechargeAgreementVi',
		component: RechargeAgreementVi,
		meta: {
			title: '充值服务协议',
			keepAlive: false
		}
	},
	{
		path: '/LiveAgreementZh', // 直播服务协议 zh
		name: 'LiveAgreementZh',
		component: LiveAgreementZh,
		meta: {
			title: '直播服务协议',
			keepAlive: false
		}
	},
	{
		path: '/LiveAgreementEn', // 直播服务协议 en
		name: 'LiveAgreementEn',
		component: LiveAgreementEn,
		meta: {
			title: '直播服务协议',
			keepAlive: false
		}
	},
	{
		path: '/LiveAgreementJa', // 直播服务协议 ja
		name: 'LiveAgreementJa',
		component: LiveAgreementJa,
		meta: {
			title: '直播服务协议',
			keepAlive: false
		}
	},
	{
		path: '/LiveAgreementKo', // 直播服务协议 ko
		name: 'LiveAgreementKo',
		component: LiveAgreementKo,
		meta: {
			title: '直播服务协议',
			keepAlive: false
		}
	},
	{
		path: '/LiveAgreementRu', // 直播服务协议 Ru
		name: 'LiveAgreementRu',
		component: LiveAgreementRu,
		meta: {
			title: '直播服务协议',
			keepAlive: false
		}
	},
	{
		path: '/LiveAgreementTh', // 直播服务协议 Th
		name: 'LiveAgreementTh',
		component: LiveAgreementTh,
		meta: {
			title: '直播服务协议',
			keepAlive: false
		}
	},
	{
		path: '/LiveAgreementVi', // 直播服务协议 Vi
		name: 'LiveAgreementVi',
		component: LiveAgreementVi,
		meta: {
			title: '直播服务协议',
			keepAlive: false
		}
	},
	{
		path: '/StoreOpeningAgreementZh', // 开店协议 Zh
		name: 'StoreOpeningAgreementZh',
		component: StoreOpeningAgreementZh,
		meta: {
			title: '开店协议',
			keepAlive: false
		}
	},
	{
		path: '/StoreOpeningAgreementEn', // 开店协议 en
		name: 'StoreOpeningAgreementEn',
		component: StoreOpeningAgreementEn,
		meta: {
			title: '开店协议',
			keepAlive: false
		}
	},
	{
		path: '/StoreOpeningAgreementJa', // 开店协议 ja
		name: 'StoreOpeningAgreementJa',
		component: StoreOpeningAgreementJa,
		meta: {
			title: '开店协议',
			keepAlive: false
		}
	},
	{
		path: '/StoreOpeningAgreementKo', // 开店协议 ko
		name: 'StoreOpeningAgreementKo',
		component: StoreOpeningAgreementKo,
		meta: {
			title: '开店协议',
			keepAlive: false
		}
	},
	{
		path: '/StoreOpeningAgreementRu', // 开店协议 ru
		name: 'StoreOpeningAgreementRu',
		component: StoreOpeningAgreementRu,
		meta: {
			title: '开店协议',
			keepAlive: false
		}
	},
	{
		path: '/StoreOpeningAgreementTh', // 开店协议 Th
		name: 'StoreOpeningAgreementTh',
		component: StoreOpeningAgreementTh,
		meta: {
			title: '开店协议',
			keepAlive: false
		}
	},
	{
		path: '/StoreOpeningAgreementVi', // 开店协议 Vi
		name: 'StoreOpeningAgreementVi',
		component: StoreOpeningAgreementVi,
		meta: {
			title: '开店协议',
			keepAlive: false
		}
	},
]