<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: "App",
		mounted() {}
	};
</script>
<style>
</style>
<style lang="scss">
	@import "@/assets/css/base.scss";
</style>
