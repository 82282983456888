import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import HB from '@/assets/js/bridge'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
// 导入VantUI库
import Vant from 'vant';
import { Toast } from 'vant';
import 'vant/lib/index.css';
// 导入全局样式表
import './assets/css/base.scss';
// import './assets/fonts/font.scss';
// 导入rem自适应
import './assets/js/rem';
// 国际化
import i18n from "./lang/i18n";
import axios from 'axios';
import apiConfig from '@/assets/api/api.config.js';
import preventReClick from '@/assets/js/preventRepeatClick';
// rsa加密
import JSEncrypt from 'jsencrypt';
Vue.prototype.$getRsaCode = function(str){ // 注册方法
  let pubKey = `-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDE3ZfgqGO6oa7xzgQ7/wKBEX3nFJRVOB9w5dZgEH6g2MR+KGhmCE7vQ/BJ0psROjUCbs5YCkyVqlmkS0uW1J299KTsEK81UvZxYVZmN28lhSWx2PmVaME+kBzMWpoDU8/F3rBzhJ+ZJ0OB+gNcyBr5Hak2ciTOn3ZOTXlmEv6X/wIDAQAB-----END PUBLIC KEY-----`;// ES6 模板字符串 引用 rsa 公钥
  let encryptStr = new JSEncrypt();
  encryptStr.setPublicKey(pubKey); // 设置 加密公钥
  let data = encryptStr.encrypt(str.toString());  // 进行加密
  return data;
}

// 配置请求的根路径
axios.defaults.baseURL = apiConfig.baseUrl;// 配置接口地址

Vue.config.productionTip = false

Vue.prototype.Toast = Toast
Vue.use(Vant);
// v-preventReClick 阻止用户频繁点击按钮 默认3秒
Vue.use(preventReClick);
// Vue.use(Toast);
Vue.use(ElementUI);
Vue.use(MintUI);

// 禁止下拉刷新
HB.disabledRefresh()

new Vue({
  router,
  store,
	i18n: i18n,
  render: h => h(App)
}).$mount('#app')
