// import zhCN from 'vant/lib/locale/lang/zh-CN'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const ja = {
	lang: {
		// login页面
		chooselanguage:'言語の選択',
		language: '言語',
		language1: '简体中文',
		language2: 'English',
		language3: '日本語',
		language4: '한국어',
		language5: 'Русский',
		language6: 'Tiếng Việt',
		language7: 'ภาษาไทย',
		cancel: 'キャンセル',
		AJtitle: 'セキュリティ検証を完了してください',
		AJsuccess: '検証成功',
		AJfailed: '検証に失敗しました',
		hintTitle: 'ヒント:',
		hintText: '確認コードが届かない場合は、メールのスパムをチェックし、メール アドレスが正しいことを確認してください。 また、unilive.team@gmail.com をホワイトリストに追加してください。',
		SignUp: '登録',
		SignUpTitle: '新しいアカウントを作成',
		email: 'メールボックス',
		email_code: '認証コード',
		getCode: '取得＃シュトク＃',
		recomId: '招待コード',
		pwd: 'パスワード',
		pwdIn: 'さいにゅうりょく',
		loginBut: '登録',
		check: '同意しました',
		userAgree: '《ユーザーアグリーメント》',
		and: 'および',
		privacyPolicy: '《プライバシーポリシー》',
		download: 'ダウンロードに進む',
		loginToast1: '同意する必要があります',
		loginToast2: '有効なメールアドレスを入力してください',
		loginToast3: 'まずメールアドレスを入力してください',
		loginToast4: 'まず認証コードを入力してください',
		loginToast5: 'まずパスワードを入力してください',
		loginToast6: 'パスワードは最低8文字必要です',
		loginToast7: 'まず確認用のパスワードを入力してください',
		loginToast8: '二回入力したパスワードが一致しません',
		loginToast9: '登録が成功しました',
		loginToast10: 'お楽しみに',
	},
	// ...zhCN,
	// ...zhLocale
}

export default ja
