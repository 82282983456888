import en_US from './en_US'
import zh_CN from './zh_CN'
import ja_JP from './ja_JP'
import ko_KR from './ko_KR'
import ru_RU from './ru_RU'
import vi_VN from './vi_VN'
import th_TH from './th_TH'
export default {
  en_US,
  zh_CN,
	ja_JP,
	ko_KR,
	ru_RU,
	vi_VN,
	th_TH
}