// import zhCN from 'vant/lib/locale/lang/zh-CN'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const th = {
	lang: {
		// login页面
			// 泰语
		chooselanguage:'เลือกภาษา',
		language: 'ภาษา',
		language1: '简体中文',
		language2: 'English',
		language3: '日本語',
		language4: '한국어',
		language5: 'Русский',
		language6: 'Tiếng Việt',
		language7: 'ภาษาไทย',
		cancel: 'ยกเลิก',
		AJtitle: 'กรุณาตรวจสอบความปลอดภัยให้เสร็จสิ้น',
		AJsuccess: 'ตรวจสอบความสำเร็จ',
		AJfailed: 'การตรวจสอบล้มเหลว',
		hintTitle: 'เคล็ดลับ：',
		hintText: 'หากคุณไม่ได้รับ CAPTCHA โปรดตรวจสอบกล่องขยะกล่องจดหมายของคุณและตรวจสอบว่าที่อยู่อีเมลถูกต้อง ขณะเดียวกันขอให้ unilive.team@gmail.com เพิ่มลงในรายการสีขาว',
		SignUp: 'ลงทะเบียน',
		SignUpTitle: 'กรุณาสร้างบัญชีของคุณ~',
		email: 'กล่องจดหมาย',
		email_code: 'รหัสยืนยัน',
		getCode: 'รับ',
		recomId: 'รหัสเชิญ',
		pwd: 'รหัสผ่าน',
		pwdIn: 'ป้อนอีกครั้ง',
		loginBut: 'ยืนยัน',
		check: 'โดยการลงทะเบียนคุณยอมรับ',
		userAgree: '《ข้อตกลงผู้ใช้》',
		and: 'และ',
		privacyPolicy: '《นโยบายความเป็นส่วนตัว》',
		download: 'ไปดาวน์โหลด',
		loginToast1: 'โปรดเลือกการยอมรับข้อตกลง',
		loginToast2: 'โปรดป้อนกล่องจดหมายที่ถูกต้อง ',
		loginToast3: 'โปรดป้อนกล่องจดหมายก่อน',
		loginToast4: 'โปรดป้อน CAPTCHA ก่อน',
		loginToast5: 'กรุณาใส่รหัสผ่านก่อน',
		loginToast6: 'รหัสผ่านอย่างน้อย 8 หลัก',
		loginToast7: 'กรุณาใส่รหัสผ่านยืนยัน',
		loginToast8: 'รหัสผ่านไม่สอดคล้องกันสองครั้ง',
		loginToast9: 'ลงทะเบียนสำเร็จ',
		loginToast10: 'โปรดติดตาม',
	},
	// ...zhCN,
	// ...zhLocale
}

export default th
